import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import Roadmap from '../components/sections/Roadmap';
import store from '../stores/mainStore';
import FeaturesSplitVariables from '../components/sections/FeaturesSplitVariables';
import { observer } from 'mobx-react';
/* import Pricing from '../components/sections/Pricing';
import Cta from '../components/sections/Cta'; */

class Home extends React.Component {
  render() {
    const {
      landing_individuals_title,
      landing_individuals_items,
      landing_exchanges_title,
      landing_exchanges_items,
      landing_legal_entities_title,
      landing_legal_entities_items,
      landing_legal_integration_title,
      landing_legal_integration_items,
    } = store.langPack;

    const items = [
      {
        title: landing_individuals_title,
        image: require('./../assets/images/home-0.svg'),
        sitems: [...landing_individuals_items],
      },
      {
        title: landing_exchanges_title,
        sitems: [...landing_exchanges_items],
        image: require('./../assets/images/home-1.svg'),
      },
      {
        title: landing_legal_entities_title,
        sitems: [...landing_legal_entities_items],
        image: require('./../assets/images/home-2.svg'),
      },
      {
        title: landing_legal_integration_title,
        sitems: [...landing_legal_integration_items],
        image: require('./../assets/images/home-3.svg'),
      }
    ]
    return (
      <React.Fragment>
        <HeroSplit imageFill className="illustration-section-01" />
        <FeaturesTiles />
        <FeaturesSplitVariables data={items} invertMobile imageFill className="illustration-section-05" />
        <Roadmap topDivider hasBgColor invertColor />
        {/* <Pricing pricingSwitcher className="illustration-section-03" />
        <Cta topDivider bottomDivider split /> */}
      </React.Fragment>
    );
  }
}

export default observer(Home);