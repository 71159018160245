import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import store from '../../stores/mainStore';
import { observer } from 'mobx-react';


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content-mobile',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: store.langPack.landing_why_choose_lh_title,
      paragraph: store.langPack.landing_why_choose_lh,
    };

    const items = [
      {
        image: require('./../../assets/images/money.svg'),
        title: store.langPack.landing_why_lh_adoption_title,
        text: store.langPack.landing_why_lh_adoption,
        delay: 1,
      },
      {
        image: require('./../../assets/images/security.svg'),
        title: store.langPack.landing_why_lh_reserves_title,
        text: store.langPack.landing_why_lh_reserves,
        delay: 2,
      },
      {
        image: require('./../../assets/images/transp.svg'),
        title: store.langPack.landing_why_lh_transparency_title,
        text: store.langPack.landing_why_lh_transparency,
        delay: 3,
      },
      {
        image: require('./../../assets/images/regular.svg'),
        title: store.langPack.landing_why_lh_regulatory_title,
        text: store.langPack.landing_why_lh_regulatory,
        delay: 1,
      },
      {
        image: require('./../../assets/images/verification.svg'),
        title: store.langPack.landing_verification_title,
        text: store.langPack.landing_verification,
        delay: 2,
      },
      {
        image: require('./../../assets/images/comment.svg'),
        title: store.langPack.landing_why_lh_support_title,
        text: store.langPack.landing_why_lh_support,
        delay: 3,
      },
    ]

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" width80/>
            <div className={tilesClasses}>{
              items.map((el, index) => (
                <div className="tiles-item" key={index}>
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-12 reveal-from-right" data-reveal-container=".tiles-item">
                      <Image
                        src={el.image}
                        alt="Features tile icon 01"
                        width={72}
                        height={72} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay={el.delay * 100}>
                      {el.title}
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay={(el.delay + 1) * 100}>
                      {el.text}
                    </p>
                  </div>
                </div>
              </div>
              ))
            }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default observer(FeaturesTiles);