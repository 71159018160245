import React from 'react';
// import section header
import Fees from '../components/sections/Fees';

class FeesPage extends React.Component {

  render() {
    return (
      
      <React.Fragment>
        <Fees className="illustration-section-05"/>
      </React.Fragment>
    );
  }
}

export default FeesPage;