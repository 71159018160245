import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import store from '../../stores/mainStore'
import { ReactComponent as Cube} from './../../assets/images/2.svg';
import { ReactComponent as Copy } from '../../assets/images/copy.svg'
import { observer } from 'mobx-react';

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
  pricingSlider: PropTypes.bool
}

/* const delimeterPrice = (value) => {
  var DecimalSeparator = Number("1.2").toLocaleString().substr(1,1);

  var AmountWithCommas = value.toLocaleString();
  var arParts = String(AmountWithCommas).split(DecimalSeparator);
  var intPart = arParts[0];
  var decPart = (arParts.length > 1 ? arParts[1] : '');
  decPart = (decPart + '00').substr(0,2);

  return intPart + DecimalSeparator + decPart + ' RUBL';
} */

function numberWithSpaces(a) {
  console.log('a', a)
  const x = a.toString();
  const first = x.split('.')[0];
  const sec  = x.split('.')[1] || '00';
  return `${first.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${sec}`;
}

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
  pricingSlider: false,
  list: [],
}

class TransparencyBox extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSwitcher,
      pricingSlider,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'pricing section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      className
    );

    const innerClasses = classNames(
      'pricing-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap illustration-section-07',
      pushLeft && 'push-left'
    );

    const {
      landing_reserve_breakdown,
      auth_but_not_issued,
      updated,
      total_authorized,
      address_contract,
      copy_to_clip,
    } = store.langPack;

    const sectionHeader = {
      title: landing_reserve_breakdown,
    };

    const fixAddresLength = (str) => {
      if (!str) return '';
      const s = 10;
      const l = str.length - 10;
      let res = '';
      for (let i = 0; i < str.length; i++) {
        if (i < s || i > l) {
          res += str[i];
        } else if (!res.includes('...')) {
          res += '...';
        } else {
          continue;
        }
      }
      return res;
    };

    const getAddr = (el) => {
      const {networkName, contractAddress} = el
      if (networkName.includes('BINANCE')) {
        return `https://bscscan.com/token/${contractAddress}`
      }

      if (networkName.includes('ETH')) {
        return `https://etherscan.io/token/${contractAddress}`
      }

      if (networkName.includes('TRON')) {
        return `https://tronscan.org/#/token20/${contractAddress}`
      }

      return ''
    }

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content invert-color" width80/>
            <div className='cube-pricing'>
              <Cube />
            </div>
            <div className={tilesClasses}>

              {this.props.list.map((el, index) => (
                <div className="tiles-item" key={index}>
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-16 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h2 text-color-low">
                        </span>
                        <span className="pricing-item-price-amount h1">
                        {el.networkName}
                        </span>
                      </div>
                      <div className="text-color-low text-xs" style={{ fontSize: '12px'}}>
                       {`${updated}: ${el.creationDate}`}
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
                        <div style={{ display: 'flex', height: '10px', width: '100%'}}>
                          <div style={{
                            display: 'flex',
                            height: '10px',
                            width: `${100 - ((el.amount * -1) / (el.totalAmount / 100))}%`,
                            backgroundColor: '#2DAFE7'
                            }} />
                          <div style={{
                            display: 'flex',
                            height: '10px',
                            width: `${(el.amount * -1) / (el.totalAmount / 100)}%`,
                            backgroundColor: '#1F487C'
                            }}
                            />
                        </div>
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li >
                          <span>{total_authorized}</span>
                          <span>{numberWithSpaces(el.totalAmount)}</span>
                        </li>
                        <li >
                          <span>{auth_but_not_issued}</span>
                          <span>{numberWithSpaces(el.amount)}</span>
                        </li>
                        {el.contractAddress && <li >
                          <span>{address_contract}</span>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="hoveredSvg" onClick={() => navigator.clipboard.writeText(el.contractAddress)}>
                            <Copy
                              fill='#959595'
                              stroke='#959595'
                              width={13} height={13}
                            />
                            <span>{copy_to_clip}</span>
                            </div>  
                            <a href={getAddr(el)} target="_blank" rel="noopener noreferrer">{fixAddresLength(el.contractAddress)}</a>    
                          </div>
                        </li>}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

TransparencyBox.propTypes = propTypes;
TransparencyBox.defaultProps = defaultProps;

export default observer(TransparencyBox);