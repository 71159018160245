import React, { useEffect, useState } from 'react';
// import section header
import TransparencyBox from '../components/sections/TransparencyBox';
import FeaturesSplitVariables from '../components/sections/FeaturesSplitVariables';

import store from '../stores/mainStore';
import { observer } from 'mobx-react';
import { getTranparency } from '../utils/api';

const Transparency = () => {
  const [transparencyList, setTransparencyList] = useState([])
  const {
    landing_transparency_lh,
    landing_transparency,
    landing_audit_title,
    landing_audit,
  } = store.langPack;

  const items = [
    {
      title: landing_transparency,
      image: require('./../assets/images/Illustration.svg'),
      sitems: [{
        title: '',
        text: landing_transparency_lh,
      }],
    },
    {
      title: landing_audit_title,
      image: require('./../assets/images/Illustration2.svg'),
      sitems: [{
        title: '',
        text: landing_audit,
      }],
      back: 4
    },
  ]

  useEffect(() => {
    getTranparency()
    .then(res => {
      const all = [{
        "id":null,
        "totalAmount": res.reduce(
          (accumulator, currentValue) => accumulator + Number(currentValue.totalAmount), 10000000.00
        ),
        "amount": res.reduce(
          (accumulator, currentValue) => accumulator + Number(currentValue.amount), -10000000.00
        ),
        "networkName":"ALL PLATFORMS",
        "networkId":1,
        "creationDate":
        "2023-01-09 18:36:01",
        "contractAddress": null
      }]
      const TRX_TEMPLATE = [{
        "id": 33,
        "totalAmount": 10000000.00,
        "amount": -10000000.00,
        "networkName":"TRON",
        "networkId":12,
        "creationDate":
        "2023-02-07 18:36:01",
        "contractAddress": 'TE2rdfJ3zBk2NkCZgcdDHgLsRkrKQrMUAp'
      }]
      const list = [...all, ...res, ...TRX_TEMPLATE]
      setTransparencyList(list)
    })
    .catch(err => console.log('err', err))
  }, [])
    return (
      
      <React.Fragment>
        <FeaturesSplitVariables data={items} invertMobile className="illustration-section-05" />
        <TransparencyBox list={transparencyList} className="illustration-section-03" />
      </React.Fragment>
    );
}

export default observer(Transparency);