import React from 'react';
// import section header
import Pdfs from '../components/sections/Pdfs';

class LegalPage extends React.Component {

  render() {
    return (
      
      <React.Fragment>
        <Pdfs className="illustration-section-05"/>
      </React.Fragment>
    );
  }
}

export default LegalPage;