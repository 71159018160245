import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
// import LayoutAlternative from './layouts/LayoutAlternative';
//import LayoutSignin from './layouts/LayoutSignin';

// Views 
import Home from './views/Home';
import FeesPage from './views/FeesPage';
/* import Login from './views/Login';
import Signup from './views/Signup'; */
import Transparency from './views/Transparency';
import AboutUs from './views/AboutUs';
import ContactForm from './components/sections/ContactForm';
import LegalPage from './views/LegalPage';
import { getLocalFile } from './utils/api'
import store from './stores/mainStore';


class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
    getLocalFile('/docs/localisation.json').then((r) => store.updateLangObject(r.data) );
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  render() {
    return (
      <>
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/transparency" component={Transparency} layout={LayoutDefault} />
            <AppRoute exact path="/fees" component={FeesPage} layout={LayoutDefault} />
            <AppRoute exact path="/about" component={AboutUs} layout={LayoutDefault} />
            <AppRoute exact path="/contact" component={ContactForm} layout={LayoutDefault} />
            <AppRoute exact path="/legal" component={LegalPage} layout={LayoutDefault} />
            {/* <AppRoute exact path="/login" component={Login} layout={LayoutSignin} />
            <AppRoute exact path="/signup" component={Signup} layout={LayoutSignin} /> */}
            <AppRoute exact path="/*" component={Home} layout={LayoutDefault} />
          </Switch>
        )} />
        </>
    );
  }
}

export default withRouter(props => <App {...props} />);