import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import { observer } from 'mobx-react';
import store from '../../stores/mainStore'
import { ReactComponent as RuLang } from '../../assets/images/ru.svg'
import { ReactComponent as EnLang } from '../../assets/images/us.svg'
import Button from '../elements/Button';


const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

class Header extends React.Component {

  state = {
    isActive: false
  };

  nav = React.createRef();
  hamburger = React.createRef();

  componentDidMount() {
    this.props.active && this.openMenu();
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    this.nav.current.style.maxHeight = this.nav.current.scrollHeight + 'px';
    this.setState({ isActive: true });
  }

  closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    this.nav.current && (this.nav.current.style.maxHeight = null);
    this.setState({ isActive: false });
  }

  keyPress = (e) => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu();
  }

  clickOutside = (e) => {
    if (!this.nav.current) return
    if (!this.state.isActive || this.nav.current.contains(e.target) || e.target === this.hamburger.current) return;
    this.closeMenu();
  }

  render() {
    const {
      className,
      active,
      navPosition,
      hideNav,
      hideSignin,
      bottomOuterDivider,
      bottomDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-header',
      bottomOuterDivider && 'has-bottom-divider',
      className
    );

    const {
      language,
      lang,
      setLang,
      langPack: {
        landing_transparency,
        about_us,
        // home,
        contact_us,
        fees,
        legal,
        sign_in,
      },
    } = store;

    const menu = [
      /* {
        label: home,
        link: '/',
      }, */
      {
        label: about_us,
        link: '/about',
      },
      {
        label: fees.fees_title,
        link: '/fees',
      },
      {
        label: legal,
        link: '/legal',
      },
      {
        label: landing_transparency,
        link: '/transparency',
      },
      {
        label: contact_us,
        link: '/contact',
      },
    ]
    return (
      <header
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-header-inner',
              bottomDivider && 'has-bottom-divider'
            )}>
              <div onClick={() => setLang(lang === 'RU' ? 'EN' : 'RU')} className="iconBox">
                        <div className='langIcon'>
                          {
                            language.name === 'RU'
                            ? <RuLang />
                            : <EnLang />
                          }
                        </div>
                      </div>
            <Logo />
            {!hideNav &&
              <React.Fragment>
                <button
                  ref={this.hamburger}
                  className="header-nav-toggle"
                  onClick={this.state.isActive ? this.closeMenu : this.openMenu}
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav
                  ref={this.nav}
                  className={
                    classNames(
                      'header-nav',
                      this.state.isActive && 'is-active'
                    )}>
                  <div className="header-nav-inner">
                    <ul className={
                      classNames(
                        'list-reset text-xxs',
                        navPosition && `header-nav-${navPosition}`
                      )}>
                      {menu.map((el, index) => (
                        <li key={index}>
                          <Link to={el.link || '/'} onClick={this.closeMenu}>{el.label}</Link>
                        </li>
                      ))}
                      <li>
                        <a href="https://app.liquid.holdings" target="_blank" rel="noopener noreferrer"><Button className="button button-primary button-wide-mobile button-sm">{sign_in}</Button></a>
                      </li>
                    </ul>
                    {/* {hideSignin &&
                      <ul
                        className="list-reset header-nav-right"
                      >
                        <li>
                          <Link to="/signup/" className="button button-primary button-wide-mobile button-sm" onClick={this.closeMenu}>Sign up</Link>
                        </li>
                      </ul>} */}
                  </div>
                </nav>
              </React.Fragment>}
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default observer(Header);