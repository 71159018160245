import Axios from 'axios';

const timeout = 30000;

export const sendContactForm = async (data) => {
    const axiosInstance = Axios.create({
      timeout,
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    });
    const link = `https://backoffice.liquid.holdings/backoffice/api/contacts`;
    return (await axiosInstance.post(link, data)).data;
};

export const getTranparency = async () => {
  const axiosInstance = Axios.create({
    timeout,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });
  // const link = `https://app-backoffice-dev.liquid.holdings/portal/api/networks/public/latest`;
  const link = `https://portal.liquid.holdings/portal/api/networks/public/latest`;
  return (await axiosInstance.get(link)).data;
};

export const getDocs= async (type) => {
  const axiosInstance = Axios.create({
    timeout,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });
  // const link = `https://app-backoffice-dev.liquid.holdings/portal/api/documents?type=${type}`;
  const link = `https://portal.liquid.holdings/portal/api/documents?type=${type}`;
  return (await axiosInstance.get(link)).data;
};

export const getLocalFile = async (dir) => {
  try {
    const result = await Axios.get(dir)
    return result;
  } catch {
    return false
  }
}