import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react';
import store from '../stores/mainStore'
import LocalStorage from './LocalStorage';


const Blinker = () => {
  const [blink, setBlink] = useState(false)
  const [language, setLanguage] = useState(LocalStorage.get('lang') || 'RU')
  const { lang, updateLang } = store;

  useEffect(() => {
    if (lang !== language) {
        setLanguage(lang);
        setBlink(true);
        setTimeout(() => updateLang(lang), 500)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang])
 
  useEffect(() => {
    blink && setTimeout(() => setBlink(false), 600);
  }, [blink])

  return (
    <div className={`blink ${!blink ? 'blink-hidden' : ''}`}>
      <div className='blink-text'>
        <h3>{lang !== 'RU' ? 'Switch language' : 'Меняем язык'}</h3>
      </div>
    </div>
    )
}
export default observer(Blinker)