import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import store from '../../stores/mainStore'
import { observer } from 'mobx-react';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { sendContactForm } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.captcha = React.createRef();

  }
  state = {
    acceptedTerms: false,
    email: '',
    firstName: '',
    lastName: '',
    message: '',
    subject: '',
    captcha: false,
    loading: false,
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const handler = (value, key) => {
      this.setState({[key]: value})
    }
    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      'illustration-section-01',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );
    
    const {
      first_name,
      last_name,
      subject,
      subject_placeholder,
      message,
      message_placeholder,
      send,
      agree,
      enter_your,
      enter_your_s,
      last_name_s,
      if_que,
      email,
      email_to,
      sendMessage,
      sendMessage_success,
      sendMessage_error,
    } = store.langPack

    const {lang  } = store

    const sectionHeader = {
      title: if_que,
    }

    const toastConfig = {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      }

    const submitHandler = async () => {
      this.setState({loading: true})
      const body = {
        acceptedTerms: this.state.acceptedTerms,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        message: this.state.message,
        subject: this.state.subject,
      }
      toast.loading(sendMessage, toastConfig)
      sendContactForm(body)
      .then(res => {
        toast.dismiss();
        toast.success(sendMessage_success, toastConfig);
      })
      .catch(err => {
        toast.dismiss();
        toast.error(sendMessage_error, toastConfig);
      })
      .finally(() => {
        this.setState({loading: false})
        this.captcha.current.resetCaptcha();
        this.setState({
          acceptedTerms: false,
          email: '',
          firstName: '',
          lastName: '',
          message: '',
          subject: '',
          captcha: false,
          loading: false,
        })
      })
    }

    const checker = (field, value) => {
      switch (field) {
        case 'email':
          var re = /\S+@\S+\.\S+/;
          return re.test(value);  
        case 'acceptedTerms':
        case 'captcha':
          return value;
        case 'loading':
          return true;
        default:
          return !!value || value.length > 3;
      }
    }

    const valid = () => {
      let fraud = 0
      const listKeys = Object.keys(this.state);
      for(let i = 0; i < listKeys.length; i++){
        if(!checker(listKeys[i], this.state[listKeys[i]])) {
          fraud++
        }
      }
      return fraud > 0
    }

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <ToastContainer />
            <div className="leftSvgBg illustration-element-09" />
            <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
            <div className="rigthSvgBg illustration-element-08" />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                    <fieldset>
                      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                      <div className="mb-12">
                        <Input
                          type="text"
                          label={first_name}
                          value={this.state.firstName}
                          onChange={(e) => handler(e.target.value, 'firstName')}
                          placeholder={`${enter_your} ${first_name.toLowerCase()}`}
                          style={{ minWidth: '350px'}}
                          required />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="text"
                          label={last_name}
                          value={this.state.lastName}
                          onChange={(e) => handler(e.target.value, 'lastName')}
                          style={{ minWidth: '350px'}}
                          placeholder={`${enter_your_s} ${last_name_s.toLowerCase()}`}
                          required />
                      </div>
                      </div>
                      <div className="mb-12">
                        <Input
                          type="email"
                          label={email}
                          value={this.state.email}
                          onChange={(e) => handler(e.target.value, 'email')}
                          placeholder={`${enter_your_s} ${email_to}`}
                          // labelHidden
                          required />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="test"
                          label={subject}
                          value={this.state.subject}
                          onChange={(e) => handler(e.target.value, 'subject')}
                          placeholder={subject_placeholder}
                          required />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="textarea"
                          label={message}
                          value={this.state.message}
                          onChange={(e) => handler(e.target.value, 'message')}
                          placeholder={message_placeholder}
                          required />
                      </div>
                      <div className="mt-24 mb-32">
                      <div className="signin-footer mb-32">
                        <Checkbox onChange={(e) => handler(e.target.checked, 'acceptedTerms')} checked={this.state.acceptedTerms}><div dangerouslySetInnerHTML={{__html: agree}}/></Checkbox>
                      </div>
                        <Button color="primary" wide disabled={valid() || this.state.loading} onClick={submitHandler}>{send}</Button>
                      </div>
                      <HCaptcha
                          ref={this.captcha}
                          sitekey="83fdd69b-c8e7-4398-9251-d6b192f5643a"
                          onVerify={(key) => !!key && handler(true, 'captcha')}
                          languageOverride={lang.toLowerCase()}
                        />
                      
                    </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default observer(ContactForm);