import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import store from '../../../stores/mainStore'
import { observer } from 'mobx-react';

const FooterNav = ({
  className,
  ...props
}) => {
  const {
    langPack: {
      landing_transparency,
      about_us,
      home,
      contact_us,
      legal,
      fees,
    },
  } = store;

  const menu = [
    {
      label: home,
      link: '/',
    },
    {
      label: about_us,
      link: '/about',
    },
    {
      label: fees.fees_title,
      link: '/fees',
    },
    {
      label: legal,
      link: '/legal',
    },
    {
      label: landing_transparency,
      link: '/transparency',
    },
    {
      label: contact_us,
      link: '/contact',
    },
  ]
  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        {
          menu.map((el, index) => ((
            <li key={index}>
            <Link to={el.link}>{el.label}</Link>
          </li>
          )))
        }
      </ul>
    </nav>
  );
}

export default observer(FooterNav);