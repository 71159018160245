import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import store from '../../stores/mainStore';
import { observer } from 'mobx-react';


const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplitVariables extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      data,
      header,
      ...props
    } = this.props;
    const {
      landing_new_tokens,
      landing_new_tokens_title,
    } = store.langPack;
    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: landing_new_tokens_title,
      paragraph: landing_new_tokens,
    };
  

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            {header && <SectionHeader data={sectionHeader} className="center-content" />}
            <div className={splitClasses}>
                {
                  data.map((el, index) => (
                    <div className="split-item reveal-scale-up" key={index}>
                    <div className="split-item-content center-content-mobile">
                    {el.title.length ? <h2 className="mt-0 mb-16">{el.title}</h2> : null}
                      {
                        el.sitems.map((el, index) => (
                          <div key={index}>
                            <h4 className="mt-8 mb-8" key={index}>{el.title}</h4>
                            <p className="m-0">{el.text}</p>
                          </div>
                        ))
                      }
                    </div>
                    <div className={
                      classNames(
                        `${el.back ? `illustration-element-0${el.back}` : ''}`,
                        imageFill && 'split-item-image-fill'
                      )}>
                      <Image
                        src={el.image}
                        alt={el.title}
                        width={528}
                        height={396} />
                    </div>
                  </div>
                  ))
                }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplitVariables.propTypes = propTypes;
FeaturesSplitVariables.defaultProps = defaultProps;

export default observer(FeaturesSplitVariables);