import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import store from '../../stores/mainStore'
import { observer } from 'mobx-react';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Fees extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const {
      fees: {
        fees_process_time,
        fees_list,
        fees_title
      }
    } = store.langPack
    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: fees_title,
      paragraph: fees_process_time
    };

    const text = (el) => ({ __html: el.text})

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              {fees_list.map((el, index) => (
                <div className="tiles-item reveal-scale-up" key={index}>
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require(`./../../assets/images/fees${index}.svg`)}
                        alt="Testimonial 01"
                        width={56}
                        height={56} />
                    </div>
                    <div className="testimonial-item-content">
                      <h4>{el.title}</h4>
                      <p className="text-sm mb-0" dangerouslySetInnerHTML={text(el)} />
                    </div>
                  </div>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Fees.propTypes = propTypes;
Fees.defaultProps = defaultProps;
    
export default observer(Fees);