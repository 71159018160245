import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Switch from '../elements/Switch';
import store from '../../stores/mainStore'
import { observer } from 'mobx-react';
import { getDocs } from '../../utils/api'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Pdfs = (props) => {
  const [list, setList] = useState([])
  const [type, setType] = useState('LEGAL')

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,      
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
  } = props;

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const { lang } = store;
    const {
      legal_docs,
      audit_docs,
      documents,
      updated,
    } = store.langPack


  const sectionHeader = {
    title: documents,
    // paragraph: fees_process_time
  };
  
  useEffect(() => {
    getDocs(type)
    .then((res) => {
      setList(res)
      console.log('res.data', res)
    })
  }, [type])
   
    
    return (
      <section
        /* {...props} */
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginBottom: '50px' }}>
            <Switch
                  checked={type === 'LEGAL' ? true : false}
                  onChange={() => setType(type === 'LEGAL' ? 'AUDIT' : 'LEGAL')}
                  rightLabel={legal_docs}>
                    {audit_docs}
                </Switch>
            </div>
            <div className={tilesClasses}>
              {(list || []).map((el, index) => (
                <a href={`https://portal.liquid.holdings/portal/api/documents/${el.id}`} key={index} style={{ display: 'flex', height: '300px', width: '300px', textDecoration: 'none'}} target="_blank" rel="noopener noreferrer">
                <div className="tiles-item reveal-scale-up">
                <div className="tiles-item-inner has-shadow" style={{ paddingTop: '15px', justifyContent: 'space-between' }}>
                  <div className="testimonial-item-header">
                  <div className="testimonial-item-image">
                      <Image
                        src={require(`./../../assets/images/pdf-svgrepo-com.svg`)}
                        alt={el.fileName}
                        width={56}
                        height={56} />
                    </div>
                    <div className="testimonial-item-content">
                      <h6>{lang === 'EN' ? el.fileNameEn : el.fileNameRu}</h6>
                    </div>
                    <div className='dateDoc'>
                    {`${updated}: ${el.creationDate}`}
                    </div>
                  </div>
                </div>
              </div>
              </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }

Pdfs.propTypes = propTypes;
Pdfs.defaultProps = defaultProps;
    
export default observer(Pdfs);