import { action, computed, decorate, observable } from 'mobx';
import { langPackDefault } from '../utils/localization';
import LocalStorage from '../utils/LocalStorage';
/* 
export type ILang = {
  RU: any;
  EN: any;
}

export type ILangT = 'RU' | 'EN'; */

class Store {
    lang = LocalStorage.get('lang') || 'RU'

    langObject = langPackDefault

    language = {
      name: LocalStorage.get('lang') || 'RU',
      langPack: this.langObject[this.lang]
    }
    setLang = (lang) => this.lang = lang

    updateLang = (lang) => {
      console.log('lang2', lang)
      this.language = {
        name: lang,
        langPack: this.langObject[lang]
      }
      LocalStorage.set('lang', lang);
    }

    updateLangObject = (o) => {
      this.langObject = {...o}
      this.language = {
        name: this.lang,
        langPack: o[this.lang]
      }
      LocalStorage.set('lang', this.lang);
    }

    get langPack() {
      return this.language.langPack
    }
}

decorate(Store, {
  lang: observable,
  langPack: computed,
  setLang: action,
  updateLang: action,
  language: observable,
  langObject: observable,
});

const mainStore = new Store();

export default mainStore;
