import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroFull from '../components/sections/HeroFull';
import GenericSection from '../components/sections/GenericSection';
// import some required elements
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';
import store from '../stores/mainStore'
import { observer } from 'mobx-react';

class AboutUs extends React.Component {

  state = {
    active: 0
  }

  setActive = (index) => {
    // e.preventDefault();
    this.setState({ active: index });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {
    const {
      about_us,
      about_us_lh_title,
      about_us_lh,
      about_us_lh_approach,
      about_us_lh_approach_title,
      about_us_lh_approach_sec,
      faq,
    } = store.langPack;

    const genericSection04Header = {
      title: faq.faq_title,
    }

    const sectionHeaderMain = {
      title: about_us_lh_title,
      paragraph: about_us_lh,
    };

    const sectionHeader = {
      title: about_us_lh_approach_title,
      paragraph: about_us_lh_approach,
    };

    const sectionHeaderSec = {
      title: '',
      paragraph: about_us_lh_approach_sec,
    };
    
    const text = (el) => ({ __html: el.text})

    return (
      <React.Fragment>
        <HeroFull className="illustration-section-01" title={about_us}/>
        <SectionHeader data={sectionHeaderMain} className="center-content"/>
        <SectionHeader data={sectionHeader} className="center-content"/>
        <SectionHeader data={sectionHeaderSec} className="center-content"/>
        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>
              {
                faq.faq_list.map((el, index) => (
                  <AccordionItem title={el.title} key={index}><span dangerouslySetInnerHTML={text(el)}/></AccordionItem>
                ))
              }
            </Accordion>
          </div>
        </GenericSection>

      </React.Fragment>
    );
  }
}

export default observer(AboutUs);